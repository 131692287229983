import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import configData from "../../config.json";
import gameService from "../../services/game.service";
import helpersService from "../../services/helpers.service";
const IMAGE_URL = configData.REACT_APP_IMAGE_URL;
const APP_URL = configData.REACT_APP_URL;
const MySwal = withReactContent(Swal)

const Friend = ({paramCredit,statePage}) => {
    const [isCopy, setIsCopy] = useState(false)
    const af = JSON.parse(localStorage.getItem('noone-wallet-af'));
    const navigate = useNavigate();
    const [affiliateBalance, setAffiliateBalance] = useState(0)
    const [affiliateCount, setAffiliateCount] = useState(0)
    const [affiliateTotal, setAffiliateTotal] = useState(0)

    const getAffiliateBalance = async () => {
        try {
            const {data: dataBalance} = await gameService.getAffiliateBalance(5);
            setAffiliateBalance(dataBalance.balance);
            setAffiliateCount(dataBalance.affiliateCount);
            setAffiliateTotal(dataBalance.affiliateTotal);
        }catch (error) {
            console.error(error)
            localStorage.clear();
            navigate('/auth/login');
        }
    }
    useEffect(() => {
        getAffiliateBalance();
    }, []);
    const copyAccount = (value) => {
        navigator.clipboard.writeText(value)
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 2000);
    }
    const exchange = async () => {
        MySwal.fire({
            icon: 'warning',               
            title: 'ยืนยันรับรายได้',
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก'
        }).then(async function(result) {
            if (result.value) {
                MySwal.fire({
                    title: 'โหลด...',
                    allowOutsideClick: false,
                    didOpen: () => {
                        MySwal.showLoading()
                    },
                });
                try {
                    const {data: dataAffiliate} = await gameService.exchangAffiliate();
                    if(dataAffiliate.code == 200){
                        paramCredit.setCreditHuay14(dataAffiliate.data.credit);
                        setAffiliateBalance(dataAffiliate.data.affiliateBalance);
                        MySwal.fire({
                            icon: 'success',               
                            title: <p>ทำรายการสำเร็จ</p>,
                        });
        
                    }
                }catch (error) {
                    console.error(error)
                    localStorage.clear();
                    navigate('/auth/login');
                }
            }
        });
       
    }

    return (
        <>
        <div className="containfriend">
            <div className="bgcutinfriend">
                <div className="headerprocess"><i className="fal fa-users-medical"></i> แนะนำเพื่อน</div>
                
                <div className="moneycontainaf mt-3">
                    <table width="100%">
                        <tbody>

                            <tr>
                                <td style={{textAlign:'center'}}>
                                   รายได้ทั้งหมด <br />
                                    <span className="cutcolor font-16">{helpersService.encodeMoneyFormat(affiliateTotal)}</span>
                                </td>
                                <td style={{textAlign:'center'}}>
                                    รายได้ปัจจุบัน <br />
                                    <span className="cutcolor font-16">{helpersService.encodeMoneyFormat(affiliateBalance)}</span>
                                    
                                </td>
                                 <td style={{textAlign:'center'}}>
                                   สมาชิกแนะนำ <br />
                                    <span className="cutcolor font-16">{helpersService.encodeMoneyFormat(affiliateCount)}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <div className="position-relative">
                       
                        <div id="withdrawfriend" >
                            <button className="btn-grad btnWithdrawAffiliate" type="button" onClick={() => {exchange()}}><i className="fas fa-hand-holding-usd"></i> แจ้งรับรายได้</button>
                        </div>
                    </div>
                     <br />
                     <br />
                    <div className="pcfriendback">
                        <div className="containlinkcopy">ลิงก์แนะนำ 
                            <table style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <td width="80%" className="text-center">
                                            <input className="friendlink" id="friend" type="text" name="link" readOnly value={APP_URL+'/register?af='+(af ? af.key : '')} />
                                        </td>
                                        <td width="20%" className="text-right">
                                            <button className="copybtn btnfriendback02" onClick={() => {copyAccount(APP_URL+'/register?af='+(af ? af.key : ''))}}>คัดลอกลิงก์</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="alert01 mt-1 font-12 text-left text-light" >
                        <span className="cutcolor font-20">ระบบแนะนำเพื่อน </span>
                        ลิ้งค์ช่วยแชร์รับ 5% ฟรี(แค่ก๊อปปี้ลิ้งค์ไปแชร์ก็ได้เงินแล้ว) ยิ่งแชร์มากยิ่งได้มากท่านสามารถนำลิ้งค์ด้านล่างนี้หรือนำไปแชร์ในช่องทางต่างๆ ไม่ว่าจะเป็น เว็บไชต์ส่วนตัว, Blog, Facebook หรือ Social Network อื่นๆหากมีการสมัครสมาชิกโดยคลิกผ่านลิ้งค์ของท่านเข้ามา ลูกค้าที่สมัครเข้ามาก็จะอยู่ภายใต้การแนะนำของท่านทันที และหากลูกค้าภายใต้การแนะนำของท่านมีการเดิมพันหวยออนไลน์ทุกประเภทเข้ามา ทุกยอดการเดิมพัน ท่านจะได้รับส่วนแบ่งในการแนะนำ 5% ทันทีโดยไม่มีเงื่อนไข</div>
                    </div>
                </div>
                
            </div>
            {statePage === 'friend' ? <>
            <br></br>
            <video  controls autoPlay={true} loop style={{width:'100%'}}>
                <source src={IMAGE_URL+"af_huay14.mp4?v=1"} type="video/mp4"/>
            </video>
            </> : null

            }
            
        </div>
        <div className="myAlert-top alert" style={{display: isCopy ? 'block' : 'none'}}>
            <i className="fad fa-check-circle font-30" style={{color: "#d2b882"}}></i>
            <br/>
            <strong className="text-light">คัดลอกเรียบร้อย!</strong>
        </div>
        </>
    );
}
export default Friend;